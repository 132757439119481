<div class="container">
    <h4 class="center">Monthly Budget Statements</h4>

    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible
                [mode]="'expandable'"
                [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                             <i class="material-icons">keyboard_arrow_down</i>
                            Monthly Budget Statements 2024-25
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                          <!-- <mz-collection-item >
                            FMR Matzikama Municipality_S71 June 2022
                                <a href="/assets/StrategicDocs/S71/2023-24/FMR Matzikama Municipality_S71 June 2024.pdf" mz-secondary-content download>
                                    {{  s71June2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            FMR Matzikama Municipality_S71 May 2024
                                <a href="/assets/StrategicDocs/S71/2023-24/FMR Matzikama Municipality_S71 May 2024.pdf" mz-secondary-content download>
                                    {{  s71May2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            FMR Matzikama Municipality_S71 April 2024
                                <a href="/assets/StrategicDocs/S71/2023-24/FMR Matzikama Municipality_S71 April 2024.pdf" mz-secondary-content download>
                                    {{  s71April2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            M09_FMR Matzikama Municipality_S71 March 2024
                                <a href="/assets/StrategicDocs/S71/2023-24/FMR Matzikama Municipality_S71 March 2024.pdf" mz-secondary-content download>
                                    {{  s71March2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item> 
                          <mz-collection-item >
                            M08_FMR Matzikama Municipality_S71 February 2024
                                <a href="/assets/StrategicDocs/S71/2023-24/FMR Matzikama Municipality_S71 February 2024.pdf" mz-secondary-content download>
                                    {{  s71February2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            M07_FMR Matzikama Municipality_S71 January 2024
                                <a href="/assets/StrategicDocs/S71/2023-24/FMR Matzikama Municipality_S71 January 2024.pdf" mz-secondary-content download>
                                    {{  s71January2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>-->
                          <mz-collection-item >
                              M06_FMR Matzikama Municipality_S71 December 2024
                                  <a href="/assets/StrategicDocs/S71/2024-25/FMR Matzikama Municipality_S71 December 2024.pdf" mz-secondary-content download>
                                      {{  s71December2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            M05_FMR Matzikama Municipality_S71 November 2024
                                <a href="/assets/StrategicDocs/S71/2024-25/FMR Matzikama Municipality_S71 November 2024.pdf" mz-secondary-content download>
                                    {{  s71November2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item> 
                          <mz-collection-item >
                              M04_FMR Matzikama Municipality_S71 October 2024
                                  <a href="/assets/StrategicDocs/S71/2024-25/FMR Matzikama Municipality_S71 October 2024.pdf" mz-secondary-content download>
                                      {{  s71October2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              M03_FMR Matzikama Municipality_S71 September 2024
                                  <a href="/assets/StrategicDocs/S71/2024-25/FMR Matzikama Municipality_S71 September 2024.pdf" mz-secondary-content download>
                                      {{  s71Sept2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              M02_FMR Matzikama Municipality_S71 August 2024
                                  <a href="/assets/StrategicDocs/S71/2024-25/FMR Matzikama Municipality_S71 August 2024.pdf" mz-secondary-content download>
                                      {{  s71August2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>  
                          <mz-collection-item >
                              M01_FMR Matzikama Municipality_S71 July 2024
                                  <a href="/assets/StrategicDocs/S71/2024-25/FMR Matzikama Municipality_S71 July 2024.pdf" mz-secondary-content download>
                                      {{  s71July2024 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>   <!-- End of first row -->
    <div class="row">
      <div class="col s12 m12 l12">
          <mz-collapsible
              [mode]="'expandable'"
              [popout]="true">
              <mz-collapsible-item [active]="true">
                  <mz-collapsible-item-header>
                           <i class="material-icons">keyboard_arrow_down</i>
                          Monthly Budget Statements 2022-23
                  </mz-collapsible-item-header>
                  <mz-collapsible-item-body>
                      <mz-collection>
                        <mz-collection-item >
                          FMR Matzikama Municipality_S71 June 2022
                              <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 June 2023.pdf" mz-secondary-content download>
                                  {{  s71June2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item >
                          FMR Matzikama Municipality_S71 May 2023
                              <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 May 2023.pdf" mz-secondary-content download>
                                  {{  s71May2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item >
                          FMR Matzikama Municipality_S71 April 2023
                              <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 April 2023.pdf" mz-secondary-content download>
                                  {{  s71April2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item >
                          M09_FMR Matzikama Municipality_S71 March 2023
                              <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 March 2023.pdf" mz-secondary-content download>
                                  {{  s71March2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item> 
                        <mz-collection-item >
                          M08_FMR Matzikama Municipality_S71 February 2023
                              <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 February 2023.pdf" mz-secondary-content download>
                                  {{  s71February2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item >
                          M07_FMR Matzikama Municipality_S71 January 2023
                              <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 January 2023.pdf" mz-secondary-content download>
                                  {{  s71January2023 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M06_FMR Matzikama Municipality_S71 December 2022
                                <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 December 2022.pdf" mz-secondary-content download>
                                    {{  s71December2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                          M05_FMR Matzikama Municipality_S71 November 2022
                              <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 November 2022.pdf" mz-secondary-content download>
                                  {{  s71November2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M04_FMR Matzikama Municipality_S71 October 2022
                                <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 October 2022.pdf" mz-secondary-content download>
                                    {{  s71October2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M03_FMR Matzikama Municipality_S71 September 2022
                                <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 September 2022.pdf" mz-secondary-content download>
                                    {{  s71Sept2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M02_FMR Matzikama Municipality_S71 August 2022
                                <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 August 2022.pdf" mz-secondary-content download>
                                    {{  s71August2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M01_FMR Matzikama Municipality_S71 July 2022
                                <a href="/assets/StrategicDocs/S71/2022-23/FMR Matzikama Municipality_S71 July 2022.pdf" mz-secondary-content download>
                                    {{  s71JuLY2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                      </mz-collection>
                  </mz-collapsible-item-body>
              </mz-collapsible-item>
              <!-- End of 2019-20 S71 Collapsible & Collection -->
          </mz-collapsible>
      </div>
  </div>   <!-- End of first row -->
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible
                [mode]="'expandable'"
                [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                             <i class="material-icons">keyboard_arrow_down</i>
                            Monthly Budget Statements 2021-22
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                          <mz-collection-item >
                            FMR Matzikama Municipality_S71 June 2022
                                <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 June 2022.pdf" mz-secondary-content download>
                                    {{  s71June2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            FMR Matzikama Municipality_S71 May 2022
                                <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 May 2022.pdf" mz-secondary-content download>
                                    {{  s71May2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            FMR Matzikama Municipality_S71 April 2022
                                <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 April 2022.pdf" mz-secondary-content download>
                                    {{  s71April2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            M09_FMR Matzikama Municipality_S71 March 2022
                                <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 March 2022.pdf" mz-secondary-content download>
                                    {{  s71March2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            M08_FMR Matzikama Municipality_S71 February 2022
                                <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 February2022.pdf" mz-secondary-content download>
                                    {{  s71February2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            M07_FMR Matzikama Municipality_S71 January 2022
                                <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 January 2022.pdf" mz-secondary-content download>
                                    {{  s71January2022 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              M06_FMR Matzikama Municipality_S71 December 2021
                                  <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 December 2021.pdf" mz-secondary-content download>
                                      {{  s71December2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                          <mz-collection-item >
                            M05_FMR Matzikama Municipality_S71 November 2021
                                <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 November 2021.pdf" mz-secondary-content download>
                                    {{  s71November2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              M04_FMR Matzikama Municipality_S71 Oktober 2021
                                  <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 October 2021.pdf" mz-secondary-content download>
                                      {{  s71October2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              M03_FMR Matzikama Municipality_S71 September 2021
                                  <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 September 2021.pdf" mz-secondary-content download>
                                      {{  s71September2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              M02_FMR Matzikama Municipality_S71 August 2021
                                  <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 August 2021.pdf" mz-secondary-content download>
                                      {{  s71August2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                          <mz-collection-item >
                              M01_FMR Matzikama Municipality_S71 July 2021
                                  <a href="/assets/StrategicDocs/S71/2021-22/FMR Matzikama Municipality_S71 July 2021.pdf" mz-secondary-content download>
                                      {{  s71July2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                      <i class=" material-icons download rigth" download>cloud_download</i>
                                  </a>
                          </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                <!-- End of 2019-20 S71 Collapsible & Collection -->
            </mz-collapsible>
        </div>
    </div>   <!-- End of first row -->
    <div class="row">
        <div class="col s12 m12 l12">

            <mz-collapsible
                [mode]="'expandable'"
                [popout]="true">

                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                             <i class="material-icons">keyboard_arrow_down</i>
                            Monthly Budget Statements 2020-21
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item >
                                M12_FMR Matzikama Municipality_S71 June 2021
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 June 2021.pdf" mz-secondary-content download>
                                        {{  s71June2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M11_FMR Matzikama Municipality_S71 May 2021
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 May 2021.pdf" mz-secondary-content download>
                                        {{  s71May2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M10_FMR Matzikama Municipality_S71 April 2021
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 April 2021.pdf" mz-secondary-content download>
                                        {{  s71April2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M09_FMR Matzikama Municipality_S71 March 2021
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 March 2021.pdf" mz-secondary-content download>
                                        {{  s71March2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M08_FMR Matzikama Municipality_S71 February 2021
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 February 2021.pdf" mz-secondary-content download>
                                        {{ s71Feb2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M07_FMR Matzikama Municipality_S71 January 2021
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 January 2021.pdf" mz-secondary-content download>
                                        {{ s71Jan2021 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M06_FMR Matzikama Municipality_S71 December 2020
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 December 2020.pdf" mz-secondary-content download>
                                        {{ s71Dec2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M05_FMR Matzikama Municipality_S71 November 2020
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 November 2020.pdf" mz-secondary-content download>
                                        {{ s71Nov2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M04_FMR Matzikama Municipality_S71 October 2020
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 Oct 2020.pdf" mz-secondary-content download>
                                        {{ s71Oct2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M03_FMR Matzikama Municipality_S71 September 2020
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 Sept 2020.pdf" mz-secondary-content download>
                                        {{ s71September2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M02_FMR Matzikama Municipality_S71 August 2020
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 August 2020.pdf" mz-secondary-content download>
                                        {{ s71August2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                            <mz-collection-item >
                                M01_FMR Matzikama Municipality_S71 July 2020
                                    <a href="/assets/StrategicDocs/S71/2020-21/FMR Matzikama Municipality_S71 July 2020.pdf" mz-secondary-content download>
                                        {{ s71July2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
                <!-- End of 2019-20 S71 Collapsible & Collection -->
            </mz-collapsible>
        </div>
    </div>   <!-- End of first row -->
<div class="row">
    <div class="col s12 m12 l12">

        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">

            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                        Monthly Budget Statements 2019-20
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            M12_FMR Matzikama Municipality_S71 June 2020
                                <a href="/assets/StrategicDocs/S71/2019-20/M12_FMR Matzikama Municipality_S71 June 2020.pdf" mz-secondary-content download>
                                    {{ s71June2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M11_FMR Matzikama Municipality_S71 May 2020
                                <a href="/assets/StrategicDocs/S71/2019-20/M11_FMR Matzikama Municipality_S71 May 2020.pdf" mz-secondary-content download>
                                    {{ s71May2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M10_FMR Matzikama Municipality_S71 April 2020
                                <a href="/assets/StrategicDocs/S71/2019-20/M10_FMR Matzikama Municipality_S71 April 2020.pdf" mz-secondary-content download>
                                    {{ s71April2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M09_FMR Matzikama Municipality_S71 March 2020
                                <a href="/assets/StrategicDocs/S71/2019-20/M08_FMR Matzikama Municipality_S71 March 2020.pdf" mz-secondary-content download>
                                    {{ s71March2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M08_FMR Matzikama Municipality_S71 February 2020
                                <a href="/assets/StrategicDocs/S71/2019-20/M08_FMR Matzikama Municipality_S71 February 2020.pdf" mz-secondary-content download>
                                    {{ s71Feb2020 | date: 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M07_FMR Matzikama Municipality_S71 January 2020
                                <a href="/assets/StrategicDocs/S71/2019-20/M07_FMR Matzikama Municipality_S71 January 2020.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M06_FMR Matzikama Municipality_S71 December 2019
                                <a href="/assets/StrategicDocs/S71/2019-20/M06_FMR Matzikama Municipality_S71 December 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M05_FMR Matzikama Municipality_S71 November 2019
                                <a href="/assets/StrategicDocs/S71/2019-20/M05_FMR Matzikama Municipality_S71 November 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M04_FMR Matzikama Municipality_S71 October 2019
                                <a href="/assets/StrategicDocs/S71/2019-20/M04_FMR Matzikama Municipality_S71 October 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M03_FMR Matzikama Municipality_S71 September 2019
                                <a href="/assets/StrategicDocs/S71/2019-20/M03_FMR Matzikama Municipality_S71 September 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M02_FMR Matzikama Municipality_S71 August 2019
                                <a href="/assets/StrategicDocs/S71/2019-20/M02_FMR Matzikama Municipality_S71 August 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M01_FMR Matzikama Municipality_S71 July 2019
                                <a href="/assets/StrategicDocs/S71/2019-20/M01_FMR Matzikama Municipality_S71 July 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}

                                   <i class=" material-icons download rigth" download>cloud_download</i>

                                </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div>
<!-- <div class="row">
    <div class="col s12 m12 l12">

        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">

            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                        Monthly Budget Statements 2018-19
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            M01_FMR Matzikama Municipality_S71 July 2018
                                <a href="/assets/StrategicDocs/S71/2018-19/M01_FMR Matzikama Municipality_S71 July 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M02_FMR Matzikama Municipality_S71 August 2018
                                <a href="/assets/StrategicDocs/S71/2018-19/M02_FMR Matzikama Municipality_S71 August 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M03_FMR Matzikama Municipality_S71 September 2018
                                <a href="/assets/StrategicDocs/S71/2018-19/M03_FMR Matzikama Municipality_S71 September 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M04_FMR Matzikama Municipality_S71 October 2018
                                <a href="/assets/StrategicDocs/S71/2018-19/M04_FMR Matzikama Municipality_S71 October 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M05_FMR Matzikama Municipality_S71 November 2018
                                <a href="/assets/StrategicDocs/S71/2018-19/M05_FMR Matzikama Municipality_S71 November 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M06_FMR Matzikama Municipality_S71 December 2018
                                <a href="/assets/StrategicDocs/S71/2018-19/M06_FMR Matzikama Municipality_S71 December 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M07_FMR Matzikama Municipality_S71 January 2019
                                <a href="/assets/StrategicDocs/S71/2018-19/M07_FMR Matzikama Municipality_S71 January 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M08_FMR Matzikama Municipality_S71 February 2019
                                <a href="/assets/StrategicDocs/S71/2018-19/M08_FMR Matzikama Municipality_S71 February 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                         <mz-collection-item >
                            M09_FMR Matzikama Municipality_S71 March 2019
                                <a href="/assets/StrategicDocs/S71/2018-19/M09_FMR Matzikama Municipality_S71 March 2019.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M10_FMR Matzikama Municipality_S71 April 2019
                            <a href="/assets/StrategicDocs/S71/2018-19/M10_FMR Matzikama Municipality_S71 April 2019.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M11_FMR Matzikama Municipality_S71 May 2019
                            <a href="/assets/StrategicDocs/S71/2018-19/M11_FMR Matzikama Municipality_S71 May 2019.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M12_FMR Matzikama Municipality_S71 June 2019
                            <a href="/assets/StrategicDocs/S71/2018-19/M12_FMR Matzikama Municipality_S71 June 2019.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div> -->
<!-- <div class="row">
    <div class="col s12 m12 l12">

        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">

            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                        Monthly Budget Statements 2017-18
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            M01_FMR Matzikama Municipality_S71 July 2017
                                <a href="/assets/StrategicDocs/S71/2017-18/M01_FMR Matzikama Municipality_S71 JULY 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M02_FMR Matzikama Municipality_S71 August 2017
                                <a href="/assets/StrategicDocs/S71/2017-18/M02_FMR Matzikama Municipality_S71 AUGUST 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M03_FMR Matzikama Municipality_S71 September 2017
                                <a href="/assets/StrategicDocs/S71/2017-18/M03_FMR Matzikama Municipality_S71 SEPTEMBER 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M04_FMR Matzikama Municipality_S71 October 2017
                                <a href="/assets/StrategicDocs/S71/2017-18/M04_FMR Matzikama Municipality_S71 OKTOBER 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M05_FMR Matzikama Municipality_S71 November 2017
                                <a href="/assets/StrategicDocs/S71/2017-18/M05_FMR Matzikama Municipality_S71 November 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M06_FMR Matzikama Municipality_S71 December 2017
                                <a href="/assets/StrategicDocs/S71/2017-18/M06_FMR Matzikama Municipality_S71 December 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}<i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M07_FMR Matzikama Municipality_S71 January 2018
                                <a href="/assets/StrategicDocs/S71/2017-18/M07_FMR Matzikama Municipality_S71 January 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M08_FMR Matzikama Municipality_S71 February 2018
                                <a href="/assets/StrategicDocs/S71/2017-18/M08_FMR Matzikama Municipality_S71 February 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                         <mz-collection-item >
                            M09_FMR Matzikama Municipality_S71 March 2018
                                <a href="/assets/StrategicDocs/S71/2017-18/M09_FMR Matzikama Municipality_S71 March 2018.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M10_FMR Matzikama Municipality_S71 April 2018
                            <a href="/assets/StrategicDocs/S71/2017-18/M10_FMR Matzikama Municipality_S71 April 2018.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M11_FMR Matzikama Municipality_S71 May 2018
                            <a href="/assets/StrategicDocs/S71/2017-18/M11_FMR Matzikama Municipality_S71 May 2018.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M12_FMR Matzikama Municipality_S71 June 2018
                            <a href="/assets/StrategicDocs/S71/2017-18/M12_FMR Matzikama Municipality_S71 June 2018.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div> -->
<!-- <div class="row">
    <div class="col s12 m12 l12">

        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">

            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                        Monthly Budget Statements 2016-17
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                        <mz-collection-item >
                            M01_FMR Matzikama Municipality_S71 July 2016
                                <a href="/assets/StrategicDocs/S71/2016-17/M01_FMR Matzikama Municipality_S71 JULIE 2016.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M02_FMR Matzikama Municipality_S71 August 2016
                                <a href="/assets/StrategicDocs/S71/2016-17/M02_FMR Matzikama Municipality_S71 AUGUST 2016.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M03_FMR Matzikama Municipality_S71 September 2016
                                <a href="/assets/StrategicDocs/S71/2016-17/M03_FMR Matzikama Municipality_S71 _SEPTEMBER 2016.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M04_FMR Matzikama Municipality_S71 October 2016
                                <a href="/assets/StrategicDocs/S71/2016-17/M05_FMR Matzikama Municipality_S71 OCTOBER 2016.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M05_FMR Matzikama Municipality_S71 November 2016
                                <a href="/assets/StrategicDocs/S71/2016-17/M05_WC011 Monthly Budget Statement_S71 November 2016.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M06_FMR Matzikama Municipality_S71 December 2016
                                <a href="/assets/StrategicDocs/S71/2016-17/M06_FMR Matzikama Municipality_S71 _DECEMBER 2016.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M07_FMR Matzikama Municipality_S71 January 2017
                                <a href="/assets/StrategicDocs/S71/2016-17/M07_FMR Matzikama Municipality_S71 JANUARY 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M08_FMR Matzikama Municipality_S71 February 2017
                                <a href="/assets/StrategicDocs/S71/2016-17/M08_ FMR Matzikama Municipality_S71 FEBRUARY 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                         <mz-collection-item >
                            M09_FMR Matzikama Municipality_S71 March 2017
                                <a href="/assets/StrategicDocs/S71/2016-17/M09_FMR Matzikama Municipality_S71 MARCH 2017.pdf" mz-secondary-content download>
                                    {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M10_FMR Matzikama Municipality_S71 April 2017
                            <a href="/assets/StrategicDocs/S71/2016-17/M10_ FMR Matzikama Municipality_S71 APRIL 2017.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M11_FMR Matzikama Municipality_S71 May 2017
                            <a href="/assets/StrategicDocs/S71/2016-17/M11_FMR Matzikama Municipality_S71 MAY 2017.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item >
                            M12_FMR Matzikama Municipality_S71 June 2017
                            <a href="/assets/StrategicDocs/S71/2016-17/M12_FMR Matzikama Municipality_S71 JUNE 2017.pdf" mz-secondary-content download>
                                {{websiteLiveDate | date: 'MMM, dd yyyy'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>
        </mz-collapsible>
    </div>
</div> -->
</div>

