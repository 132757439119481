import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.css']
})
export class VacanciesComponent implements OnInit {

  extendMunManAndDir : Date = new Date('31 March 2020 16:02:43')
  UploadDate =Date.now();
  v52020: Date = new Date('19 March 2020 17:20:52');
  v52020_1: Date = new Date('03 April 2020 18:01:26');
  mmAndDirAdAmendment: Date = new Date('03 April 2020 18:29:38');
  ManagerInfraAug2020: Date = new Date('2020-08-17 11:23');
  ManagerElecFeb2021: Date = new Date('2021-02-05 14:07');
  municipalManagerMarch2021: Date = new Date('2021-03-13 19:50');
  municipalManagerApril2021: Date = new Date('2021-05-09 11:22');
  municipalManagerAugust2021: Date = new Date('2021-08-13 15:33');
  V12_2021:Date = new Date('2021-10-07 10:53');
  V14_2021:Date = new Date('2021-11-26 15:50');
  V13_2021:Date = new Date('2021-11-29 11:08');
  V1_2022:Date = new Date('2022-01-20 12:25');
  V4_2022:Date = new Date('2022-03-09 14:10');
  V5_2022:Date = new Date('2022-03-17 15:10');
  V7_2022:Date = new Date('2022-05-12 16:27');
  V8_2022:Date = new Date('2022-05-23 10:27');
  V10_2022:Date = new Date('2022-07-15 14:53');
  V11_2022:Date = new Date('2022-08-30 08:38');
  V12_2022:Date = new Date('2022-10-04 15:47');
  V13_2022:Date = new Date('2022-10-14 09:29');
  V1_2023:Date = new Date('2023-02-06 08:24');
  V2_2023:Date = new Date('2023-03-09 15:51');
  V4_2023:Date = new Date('2023-04-16 10:08');
  V5_2023:Date = new Date('2023-04-24 16:42');
  V6_2023:Date = new Date('2023-06-29 09:45');
  V7_2023:Date = new Date('2023-07-18 15:37');
  dws202309:Date = new Date('2023-09-11 20:49');
  V8_2023:Date = new Date('2023-09-15 10:19');
  AdminAssist:Date = new Date('2023-09-29 12:20');
  V10_2023:Date = new Date('2023-10-08 15:53');
  V11_2023:Date = new Date('2023-10-20 12:04');
  V1_2024:Date = new Date('2024-01-24 13:29');
  V2_2024:Date = new Date('2024-01-26 21:11');
  peace2024:Date = new Date('2024-02-16 10:21');
  V5_2024:Date = new Date('2024-02-29 11:46');
  V5_2024_2:Date = new Date('2024-02-29 12:06');
  V6_2024:Date = new Date('2024-04-15 09:15');
  V3_2024:Date = new Date('2024-04-19 14:06');
  V7_2024:Date = new Date('2024-04-22 09:26');
  V9_2024:Date = new Date('2024-06-05 14:01');
  V10_2024:Date = new Date('2024-06-18 10:10');
  V12_2024:Date = new Date('2024-08-20 08:41');
  V13_2024:Date = new Date('2024-09-11 08:52');
  V14_2024:Date = new Date('2024-10-07 15:19');
  V16_2024:Date = new Date('2024-11-14 13:49');
  V19_2024:Date = new Date('2024-12-24 09:48');


































  constructor( private datepipe:DatePipe) {

    const vacancyUploadDate = this.datepipe.transform(this.UploadDate,'medium')
  }



  ngOnInit() {
  }

}
