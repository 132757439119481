<h4 class="center">Municipal Notices</h4>
<div class="container">
    <div class="row">
        <div class="col s12 m12 l12">
            <mz-collapsible [mode]="'expandable'" [popout]="true">
                <mz-collapsible-item [active]="true">
                    <mz-collapsible-item-header>
                        <i class="material-icons">keyboard_arrow_down</i>
                           Municipal Notices 2024-25
                    </mz-collapsible-item-header>
                    <mz-collapsible-item-body>
                        <mz-collection>
                            <mz-collection-item>
                                Matzikama Municipality Progress Report January 2025
                                    <a href="/assets/municipal-notices/2024-25/Vorderingsverslag.pdf" mz-secondary-content download>
                                        {{progressReport2025 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                        <i class=" material-icons download rigth" download>cloud_download</i>
                                    </a>
                                </mz-collection-item>
                            <mz-collection-item>
                            Strandfontein Easter Holiday Booking 2025    
                                <a href="/assets/municipal-notices/2024-25/Strandfontein Easter booking2024.pdf" mz-secondary-content download>
                                    {{strandEaster2025 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                            S33 Intention To Invite For Tenders For Commercial Banking Services
                                <a href="/assets/municipal-notices/2024-25/sec 33 banking.pdf" mz-secondary-content download>
                                    {{s33BankingInfoSheet | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                S33 Commercial Banking Information Sheet
                                <a href="/assets/municipal-notices/2024-25/SEC 33 information statement.pdf" mz-secondary-content download>
                                    {{s33Banking | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>                           
                            </mz-collection-item>
                            <mz-collection-item>
                                EXTENSION OF TRAINING FQ'S CLOSING DATE
                                <a href="/assets/municipal-notices/2024-25/Extension of Training FQ's.pdf" mz-secondary-content download>
                                    {{TrainingFQs | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                VERLENGING VAN HANDELSDAE EN URE:  VREDENDAL GHOLFKLUB
                                <a href="/assets/municipal-notices/2024-25/K22 Gholfklub.pdf" mz-secondary-content download>
                                    {{K22Gholfklub | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                IDP TIME SCHEDULE 2024-25
                                <a href="/assets/municipal-notices/2024-25/Time Schedule - Approved 27 August 2024.pdf" mz-secondary-content download>
                                    {{TimeScheduleSept202526 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                PUBLIC PARTICIPATION SCHEDULE 2024-25
                                <a href="/assets/municipal-notices/2024-25/Public Participation Schedule 2526.pdf" mz-secondary-content download>
                                    {{IDPMeetingsSept202526 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                STRANDFONTEIN RESERVATIONS – FESTIVE SEASON APPLICATION FORM
                                <a href="/assets/municipal-notices/2024-25/STRANDFONTEIN APPLICATION FORM.pdf" mz-secondary-content download>
                                    {{strandfontein2425 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                EXTENSION OF FQ01:2024-2025 ONLINE RESERVATION BOOKING SYSTEM
                                <a href="/assets/municipal-notices/2024-25/EXTENSION OF FQ -012024-2025.pdf" mz-secondary-content download>
                                    {{nersaLetter0207 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                            <mz-collection-item>
                                NERSA ELECTRICITY TARIFFS APPROVAL LETTER
                                <a href="/assets/municipal-notices/2024-25/20240628_Matzikama Municipality_Electricity Tarrifs for the period 1 July 2024 - 30 June 2025.pdf" mz-secondary-content download>
                                    {{nersaLetter0207 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                            </mz-collection-item>
                        </mz-collection>
                    </mz-collapsible-item-body>
                </mz-collapsible-item>
            </mz-collapsible>
        </div>
    </div>
</div>
<div class="container">
  <div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
        <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
                 <i class="material-icons">keyboard_arrow_down</i>
               Municipal Notices 2023-24
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
            <mz-collection>
                <mz-collection-item >
                    KENNISGEWING AGTERSTALLIGE  HUISWINKELS
                    <a href="/assets/municipal-notices/2023-24/KENNISGEWING AGTERSTALLIGE  HUISWINKELS.pdf" mz-secondary-content download>
                        {{huiswinkelAfr | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    NOTICE OVERDUE HOUSE SHOPS
                    <a href="/assets/municipal-notices/2023-24/NOTICE OVERDUE HOUSE SHOPS.pdf" mz-secondary-content download>
                        {{huiswinkelEng | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    APPLICATION FOR EXTENSION OF LIQUOR TRADING HOURS AT RASTA'S TAVERN
                    <a href="/assets/municipal-notices/2023-24/rasta202402.pdf" mz-secondary-content download>
                        {{rasta202402 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    PA Director Community Development Services
                        <a href="/assets/municipal-notices/2023-24/Dir CDS.pdf" mz-secondary-content download>
                            {{ Agreements202324CDS| date: 'MMM, dd yyyy , HH:mm:aaa'}}
                            <i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    PA Municipal Manager
                          <a href="/assets/municipal-notices/2023-24/MM.pdf" mz-secondary-content download>
                              {{Agreements202324MM | date: 'MMM, dd yyyy , HH:mm:aaa'}}
                              <i class=" material-icons download rigth" download>cloud_download</i></a>
                </mz-collection-item>
                <mz-collection-item >
                    MATZIKAMA SSEG POLICY
                    <a href="/assets/municipal-notices/2023-24/Matzikama SSEG Policy (14 Oct 2023).pdf" mz-secondary-content download>
                        {{ssegPolicy | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    MATZIKAMA SSEG APPLICATION
                    <a href="/assets/municipal-notices/2023-24/Matzikama SSEG Application Form (Sep2023).pdf" mz-secondary-content download>
                        {{ssegApp | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    MATZIKAMA SSEG REQUIREMENTS
                    <a href="/assets/municipal-notices/2023-24/Matzikama SSEG Requirements (Feb2023).pdf" mz-secondary-content download>
                        {{ssegReq | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    BUILDING CONTROL NOTICE
                    <a href="/assets/municipal-notices/2023-24/Public Notice Nov-Jan Building Plan App Info.pdf" mz-secondary-content download>
                        {{buildingControlNotice20102023 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    TOWNPLANNING NOTICE
                    <a href="/assets/municipal-notices/2023-24/Public Notice Nov- Jan Land Use App Info.pdf" mz-secondary-content download>
                        {{townplanNotice20102023 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                    IDP TIME SCHEDULE 2023-24
                    <a href="/assets/municipal-notices/2023-24/Time Schedule and Key deadlines Aug 2023-Jul 2023 - Approved by Council 29 August 2023.pdf" mz-secondary-content download>
                        {{idp2324deadlines | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                  </mz-collection-item>
                <mz-collection-item >
                    PUBLIC PARTICIPATION SCHEDULE 2023-24
                    <a href="/assets/municipal-notices/2023-24/Mat_Mun_publieke_deelname GOP (003).pdf" mz-secondary-content download>
                        {{idp2324 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                  </mz-collection-item>
                <mz-collection-item >
                    EXTENTION OF CLOSING DATE FOR FQ3:2023-2024
                    <a href="/assets/municipal-notices/2023-24/EXTENTION OF CLOSING DATE FOR FQ3_2023_2024.pdf" mz-secondary-content download>
                        {{Fq03Extention202324 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                        <i class=" material-icons download rigth" download>cloud_download</i>
                    </a>
                </mz-collection-item>
                <mz-collection-item >
                STRANDFONTEIN BOOKINGS
                <p>Bookings for the Strandfontein festive period (15 December 2023 - 15 January 2024) will be opening on Tuesday, 11 July 2023 at 09:00. 
                  Bookings can be made at the following link:</p>
                <a href="https://book.nightsbridge.com/28520 " target="_blank">Book Now</a>
                <div></div>
                    {{StrandFonteinBookings202324 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
          
                </mz-collection-item>
                <mz-collection-item >
                NERSA TARIFFS APPROVAL LETTER 
                <a href="/assets/municipal-notices/2023-24/20230630_Letter_Matzikama Municipality_Electricity Tariffs for the period 1 July 2023 - 30 June 2024.pdf" mz-secondary-content download>
                    {{nersaLetter202324 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
            </mz-collection>
        </mz-collapsible-item-body>
      </mz-collapsible-item>
    </mz-collapsible>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col s12 m12 l12">
      <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">

      <mz-collapsible-item [active]="true">
        <mz-collapsible-item-header>
                 <i class="material-icons">keyboard_arrow_down</i>
               Municipal Notices 2022-23
        </mz-collapsible-item-header>
        <mz-collapsible-item-body>
            <mz-collection>
              <mz-collection-item class="truncate">
                WC011 - MATZIKAMA MUNICIPALITY COST ELECTRICITY OF SUPPLY STUDY 2021 2022
                <a href="/assets/municipal-notices/2023/WC011 - MATZIKAMA MUNICIPALITY COST OF SUPPLY STUDY 2021 2022.pdf" mz-secondary-content download>
                    {{elecSupply2122 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                PUBLIC PARTICIPATION:  REVIEW OF THE INTEGRATED DEVELOPMENT PLAN (IDP) AND DRAFT BUDGET 2023-2024
                <a href="/assets/municipal-notices/2023/Mat_Mun_K36_4_April (003).pdf" mz-secondary-content download>
                    {{k362023 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                SECOND AND FINAL NOTICE: PROPOSED DRAFT INTEGRATED ZONING SCHEME BY-LAW
                <a href="/assets/municipal-notices/2023/2de kennisgewing- MMIZSB soos 1 Maart 2023.pdf" mz-secondary-content download>
                    {{fq272023 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                FQ27:2022-2023  SERVICE PROVIDER TO COLLECT RAW DATA REGARDING BULK ELECTRICITY METERS
                <a href="/assets/municipal-notices/2023/FQ27.pdf" mz-secondary-content download>
                    {{fq272023 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                FQ28:2022-2023  INSTALLATION AND COMMISSION OF TRAFFIC LIGHT CONTROLLER
                <a href="/assets/municipal-notices/2023/FQ28.pdf" mz-secondary-content download>
                    {{fq282023 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                NOTICE REGARDING SPAZA SHOPS
                <a href="/assets/municipal-notices/20221125130929322.pdf" mz-secondary-content download>
                    {{spazaShopsNotice241129 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                NOTICE REGARDING BUILDING PLAN SUBMISSIONS
                <a href="/assets/municipal-notices/20221121151030956.pdf" mz-secondary-content download>
                    {{buildingPlanNotice241122 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item >
                PRESS RELEASE: MATZIKAMA WATER
                <a href="/assets/municipal-notices/24 November 2022 - Press release.pdf" mz-secondary-content download>
                    {{waterNotice241122 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                PLEASE BOIL DRINKING WATER BEFORE CONSUMPTION.
                <a href="/assets/municipal-notices/20221109145721287.pdf" mz-secondary-content download>
                    {{waterNotice091122 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                    <i class=" material-icons download rigth" download>cloud_download</i>
                </a>
              </mz-collection-item>
              <mz-collection-item class="truncate">
                LGSETA Bursary List of Qualifying Study Fields
                 <a href="/assets/municipal-notices/List of LGSETA Bursary Qualifications 2022-2023.pdf" mz-secondary-content download>
                     {{LgsetaBursariesOctober202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                     <i class=" material-icons download rigth" download>cloud_download</i>
                 </a>
               </mz-collection-item>
               <mz-collection-item class="truncate">
                 LGSETA Bursary User Guide
                  <a href="/assets/municipal-notices/2022 Individual Bursary User Manual (Updated).pdf" mz-secondary-content download>
                      {{LgsetaBursariesOctober202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
                <mz-collection-item class="truncate">
                 LGSETA Bursary Information
                  <a href="/assets/municipal-notices/DG Advert for LGSETA_(Bursary)pdf.pdf" mz-secondary-content download>
                      {{LgsetaBursariesOctober202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                      <i class=" material-icons download rigth" download>cloud_download</i>
                  </a>
                </mz-collection-item>
            </mz-collection>
        </mz-collapsible-item-body>
      </mz-collapsible-item>
    </mz-collapsible>
    </div>
  </div>
</div>
<div class="container">
<div class="row">
    <div class="col s12 m12 l12">
        <mz-collapsible
            [mode]="'expandable'"
            [popout]="true">
            <mz-collapsible-item [active]="true">
                <mz-collapsible-item-header>
                         <i class="material-icons">keyboard_arrow_down</i>
                        Municipal Notices
                </mz-collapsible-item-header>
                <mz-collapsible-item-body>
                    <mz-collection>
                      <mz-collection-item class="truncate">
                        PUBLIC PARTICIPATION MEETINGS: REVIEW OF THE INTEGRATED DEVELOPMENT PLAN (IDP) 2023-2024
                                 <a href="/assets/municipal-notices/Matzikama_Mun_K9.pdf" mz-secondary-content download>
                                     {{IDPMeetingsOctober202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        PROCESS PLAN FOR 2022 2027 INTEGRATED DEVELOPMENT PLAN AND BUDGET
                                 <a href="/assets/municipal-notices/Process Plan 2223.pdf" mz-secondary-content download>
                                     {{IDPProcessPlan202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        IDP AND BUDGET TIME SCHEDULE: 2022/23
                                 <a href="/assets/municipal-notices/Time Schedule 2223.pdf" mz-secondary-content download>
                                     {{IDPTimeSchedule202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        KENNISGEWING VIR INSPEKSIE VAN DIE AANVULLENDE WAARDASIEROL
                                 <a href="/assets/municipal-notices/K1202122.pdf" mz-secondary-content download>
                                     {{K1202122 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        OPENBARE KENNISGEWING VIR INSPEKSIE VAN DIE AANVULLENDE WAARDASIEROL
                                 <a href="/assets/municipal-notices/K11202122.pdf" mz-secondary-content download>
                                     {{K11202122 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        MATZIKAMA MUNICIPALITY DRAFT PROCESS PLAN NOTICE
                                 <a href="/assets/municipal-notices/IDP Process Plan 2022.png" mz-secondary-content download>
                                     {{idpProcessPlanNoticeJuly2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                         </mz-collection-item>
                      <mz-collection-item class="truncate">
                      MATZIKAMA MUNICIPALITY DRAFT PROCESS PLAN 2022-2027
                               <a href="/assets/municipal-notices/Draft Process Plan for 2022-2027 IDP.pdf" mz-secondary-content download>
                                   {{idpProcessPlanJuly2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                   <i class=" material-icons download rigth" download>cloud_download</i>
                               </a>
                       </mz-collection-item>
                       <mz-collection-item class="truncate">
                        MATZIKAMA MUNICIPALITY TIME SCHEDULE
                                 <a href="/assets/municipal-notices/Annexure A - Draft Time Schedule - read in conjunction with Draft Process Plan for 2022-2027 IDP.pdf" mz-secondary-content download>
                                     {{idpTimeScheduleJuly2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                     <i class=" material-icons download rigth" download>cloud_download</i>
                                 </a>
                         </mz-collection-item>
                      <mz-collection-item class="truncate">
                       IMPORTANT NOTICE: WATER SUPPLY
                              <a href="/assets/municipal-notices/Water kennisgewing1.pdf" mz-secondary-content download>
                                  {{WaterKennisgewingJuly2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                  <i class=" material-icons download rigth" download>cloud_download</i>
                              </a>
                      </mz-collection-item>
                    <mz-collection-item class="truncate">
                      Approved Concept Matzikama Zoning Scheme 30 May 2022
                            <a href="/assets/municipal-notices/Approved Concept Matzikama Zoning Scheme 30 May 2022 PPP (004).pdf" mz-secondary-content download>
                                {{SoneringSkemaBylaw2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                    </mz-collection-item>
                    <mz-collection-item class="truncate">
                      Approved Concept Matzikama Zoning Scheme Advertisement
                            <a href="/assets/municipal-notices/Sonering Skema Advertensie.pdf" mz-secondary-content download>
                                {{SoneringSkemaBylaw2022Ad | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        EPWP 2022-2023 Notice
                            <a href="/assets/municipal-notices/EPWP KENNISGEWING.pdf" mz-secondary-content download>
                                {{idSummaryAd | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        EPWP 2022-2023 Application Form
                            <a href="/assets/municipal-notices/Aansoek vorm - EPWP project.pdf" mz-secondary-content download>
                                {{idSummaryAd | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        IDP Executive Summary Advertisement
                            <a href="/assets/StrategicDocs/idp/Mat_mun_K40.pdf" mz-secondary-content download>
                                {{idSummaryAd | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        Municipal Internship Database 2022-2023
                            <a href="/assets/municipal-notices/V10-2022- Registrasie op databasis vir Internskap- Matz Mun -(2022-2023).pdf" mz-secondary-content download>
                                {{internship202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i>
                            </a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        Municipal Internship Database 2022-2023 Application Form
                            <a href="/assets/municipal-notices/Application form - Registration on municipal data base for Internship.pdf" mz-secondary-content download>
                              {{internship202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                              <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        NARYSEC RECRUITMENT MATZIKAMA MUNICIPALITY
                            <a href="/assets/municipal-notices/NARYSEC ADVERT.pdf" mz-secondary-content download>
                                {{narysec202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        NARYSEC RECRUITMENT MATZIKAMA MUNICIPALITY  Application Form
                            <a href="/assets/municipal-notices/NARYSEC APPLICATION FORM.pdf" mz-secondary-content download>
                                {{narysec202223 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        IMPLEMENTATION OF NON AUTOMATIC LIQUOR LICENCE RENEWALS
                            <a href="/assets/municipal-notices/Implementation of Section 64 Renewals.pdf" mz-secondary-content download>
                                {{liquorLicence | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        TRONOX REVIEW OF SOCIAL AND LABOUR PLAN
                            <a href="/assets/municipal-notices/Tronox Mineral Sands (Pty) Ltd - Review of Social and Labour Plan and Public Participation 05 2022 v2.pdf" mz-secondary-content download>
                                {{tronoxSLP | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        MUNICIPAL COUNCIL MEETING 31 MAY 2022 AT 10:00 AM
                            <a href="/assets/municipal-notices/K35.2022 RAADSVERGADERING.pdf" mz-secondary-content download>
                                {{k35 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        PUBLIC PARTICIPATION MEETINGS SCHEDULE
                            <a href="/assets/municipal-notices/Mat_Mun_K28.pdf" mz-secondary-content download>
                                {{PublicPartMeetingsMarch2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        DRIE FASE KRAG
                            <a href="/assets/municipal-notices/Drie Fase Krag.pdf" mz-secondary-content download>
                                {{drieFaseKragMarch2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        ADVERT:FINANCIAL ASSISTANCE TO STUDENT(S): 2021/2022
                            <a href="/assets/municipal-notices/Advert - Financial Assistance for Students 2022.pdf" mz-secondary-content download>
                                {{bursariesFeb2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        APPLICATION FORM:FINANCIAL ASSISTANCE TO STUDENT(S): 2021/2022
                            <a href="/assets/municipal-notices/Matzikama Municipality Application Form - Financial Assistance for Students 2022.pdf" mz-secondary-content download>
                                {{bursariesFeb2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        ADVERT:FULL–TIME STUDY BURSARIES FOR FIVE (5) STUDENTS FOR 2022
                            <a href="/assets/municipal-notices/Advert - Full time bursaries 2022.pdf" mz-secondary-content download>
                                {{bursaries2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        APPLICATION FORM:FULL–TIME STUDY BURSARIES FOR FIVE (5) STUDENTS FOR 2022
                            <a href="/assets/municipal-notices/Application form - Full-time Bursaries 2022.pdf" mz-secondary-content download>
                                {{bursaries2022 | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        KENNISGEWING: BEDRYF VAN HUISWINKELS
                            <a href="/assets/municipal-notices/Huiswinkel kennisgewing 24-01-2022.pdf" mz-secondary-content download>
                                {{bedryfHuiswinkels | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                      </mz-collection-item>
                      <mz-collection-item class="truncate">
                        MATZIKAMA MUNICIPALITY CANCELLATION OF ALL BOOKED EVENTS
                            <a href="/assets/municipal-notices/Covid over festive season Dec 2021.pdf" mz-secondary-content download>
                                {{eventsCancel | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                <i class=" material-icons download rigth" download>cloud_download</i></a>
                    </mz-collection-item>
                        <mz-collection-item class="truncate">
                            MATZIKAMA MUNISIPALITEIT KENNISGEWING – HEFFING VAN BESKIKBAARHEIDSFOOIE
                                <a href="/assets/municipal-notices/KENNISGEWING - HEFFING VAN BESKIKBAARHEIDSFOOIE(AFR).pdf" mz-secondary-content download>
                                    {{availFeesAFR | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            MATZIKAMA MUNICIPALITY NOTICE – LEVYING OF AVAILABILITY FEES
                                <a href="/assets/municipal-notices/NOTICE  LEVYING OF AVAILABILITY FEES(ENG).pdf" mz-secondary-content download>
                                    {{availFeesENG | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            MATZIKAMA MUNICIPALITY AFFORDABLE HOUSING DEVELOPMENT PROJECT
                                <a href="/assets/rfp/Affordable Housing.pdf" mz-secondary-content download>
                                    {{rfpHousing | date : 'MMM, dd yyyy, HH:mm:aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i></a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            STATS SA 2022 CENSUS Employment Database
                                <a href="/assets/municipal-notices/Stats SA Database Advert.pdf" mz-secondary-content download>
                                    {{statsSA |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            IDP 2021-2022 Public Participation
                                <a href="/assets/municipal-notices/K13.PDF" mz-secondary-content download>
                                    {{K13_2021_2022 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            APPROVED 2020/2021 ADJUSTMENT CAPITAL AND OPERATING BUDGET
                                <a href="/assets/municipal-notices/K1-2021-2022.pdf" mz-secondary-content download>
                                    {{K1_2021_2022 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            PUBLIC PARTICIPATION MEETINGS FOR THE FOURTH REVIEW (2021/2022) IDP
                                <a href="/assets/municipal-notices/Kennisgewing K11.pdf" mz-secondary-content download>
                                    {{publicParticipationMarch2021 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            REGISTRATION ON DATABASE FOR MUNICIPAL INTERNSHIP (2021/2022) ADVERT
                                <a href="/assets/municipal-notices/Advert - Registration for Internship.pdf" mz-secondary-content download>
                                    {{internDatabaseMarch2021 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            REGISTRATION ON DATABASE FOR MUNICIPAL INTERNSHIP (2021/2022) APPLICATION FORM
                                <a href="/assets/municipal-notices/Application form - Registration on municipal data base for Internship.pdf" mz-secondary-content download>
                                    {{internDatabaseMarch2021 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            FULL–TIME STUDY BURSARIES 2021
                                <a href="/assets/municipal-notices/Full time bursaries for 2021.pdf" mz-secondary-content download>
                                    {{bursariesMarch2021 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            APPLICATION FORM: FULL–TIME STUDY BURSARIES 2021
                                <a href="/assets/municipal-notices/Application form - Full-time Bursaries.pdf" mz-secondary-content download>
                                    {{bursariesMarch2021 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            Adjustment Budget December 2020  Approval
                                <a href="/assets/municipal-notices/Mat_mun_K56.pdf" mz-secondary-content download>
                                    {{adjustbudgetDec2020 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            Mayoral Committee Outreach Programme
                                <a href="/assets/municipal-notices/MC Outreach.pdf" mz-secondary-content download>
                                    {{mcop2020 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            Strandfontein Kiosk
                                <a href="/assets/municipal-notices/Kiosk advertensie.pdf" mz-secondary-content download>
                                    {{kiosk2020 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            Hawkers Stands
                                <a href="/assets/municipal-notices/Smous Stalletjies advert.pdf" mz-secondary-content download>
                                    {{hawkers2020 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            REQUEST FOR INFORMATION: TRONOX LED PROJECTS
                                <a href="/assets/municipal-notices/Tronox-LED.pdf" mz-secondary-content download>
                                    {{TronoxLED |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                        <mz-collection-item class="truncate">
                            2019-20 ASSET DISPOSAL LIST
                                <a href="/assets/Assets/LIST OF DISPOSALS (2020 10 27).pdf" mz-secondary-content download>
                                    {{AssetDisposal1920 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </mz-collection-item>
                    </mz-collection>
                </mz-collapsible-item-body>
            </mz-collapsible-item>

        </mz-collapsible>
    </div>
</div>
</div>


<!-- <div class="container">
    <div class="row">
        <div class="col s12 m12 l12">
            <ul class="collapsible popout">
                <li>
                  <div class="collapsible-header"><i class="material-icons">keyboard_arrow_down</i>Notices</div>
                  <div class="collapsible-body"><span>
                    <ul class="collection with-header">
                        <li class="collection-header"><h4>First Names</h4></li>
                        <li class="collection-item">
                            FULL–TIME STUDY BURSARIES 2021
                                <a href="/assets/municipal-notices/Mat_mun_K56.pdf" mz-secondary-content download>
                                    {{adjustbudgetDec2020 |date : 'MMM, dd yyyy, HH:mm aaa'}}
                                    <i class=" material-icons download rigth" download>cloud_download</i>
                                </a>
                        </li>
                      </ul>
                  </span></div>
                </li>
                <li>
                  <div class="collapsible-header"><i class="material-icons">place</i>Second</div>
                  <div class="collapsible-body"><span>Lorem ipsum dolor sit amet.</span></div>
                </li>
                <li>
                  <div class="collapsible-header"><i class="material-icons">whatshot</i>Third</div>
                  <div class="collapsible-body"><span>Lorem ipsum dolor sit amet.</span></div>
                </li>
              </ul>
        </div>
    </div>

</div> -->


